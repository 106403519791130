import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import PropTypes from "prop-types";

class IndexPage extends React.Component {
  componentDidMount() {
    this.context.mixpanel.track("Home.Load");
  }

  render() {
    return (
      <Layout showHeader={true} showFooter={true}>
        <p>Hi there, it's nice to meet you. Here is a quick background on me.</p>
        <p>When everyone was getting a Nintendo in the 80s, my parents gave me a computer. A Tandy 1000 EX that ran MS-DOS off a 5.25" floppy drive with 256kb of RAM. I spent most of my free time on that machine and learned to program in BASIC from a book picked up at Radioshack.</p>
        <p>Since those early days, my professional and free time has been spent making software.</p>
        <p>I have been an engineer in some roles and immersed myself in data structures, algorithms, language design, and compilers. I have been more on the business side in other functions and studied marketing, sales, and financial models.</p>
        <p>While the titles I have had have changed, one interest has not changed ever since I got that Tandy. The experience you have when you are operating a device running software. That is ultimately where I am most passionate, and it has been what has guided all of the work I have done.</p>
        <p>Currently, I am working as a <a href="https://linkedin.com/in/christopherandersson">Staff Product Designer</a> at <a href="https://datadoghq.com">Datadog</a> (<a href="https://www.datadoghq.com/careers/">come work with me</a>). I am also delighted to meet new people with the same interests, so please <a href="mailto:hi@christopherandersson.com">contact me</a>.</p>
      </Layout>
    );
  }
}

export default IndexPage;

IndexPage.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};
